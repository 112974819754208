import React, { Component } from "react";
import WebFont from "webfontloader";

import Routes from "./Routes";

class App extends Component {
  state = {
    fontsLoaded: false,
  };

  componentDidMount() {
    const _self = this;
    WebFont.load({
      custom: {
        families: ["Inter:n5,n7"],
        urls: ["/webfonts/inter.css"],
      },
      loading: function () {
        console.log("WebFont loading");
      },
      inactive: function () {
        _self.setState({ fontsLoaded: true }); // fonts loaded but failed - prob slow connection???
        console.log("WebFont inactive");
      },
      active: function () {
        _self.setState({ fontsLoaded: true });
        console.log("WebFont Ready");
      },
    });
  }

  render() {
    if (!this.state.fontsLoaded) {
      return <p>Loading...</p>;
    }

    return <Routes />;
  }
}

export default App;
