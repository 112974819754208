import React, { useState } from "react";
import { Field } from "formik";

import Label from "./Label";

const Textarea = ({
  rows = 3,
  label = false,
  maxLength = false,
  placeholder = "",
  hasError = false,
  className = (hasError
    ? "border-red-200 bg-red-50 focus:ring-red-300 focus:border-red-300  placeholder-red-500 "
    : "border-gray-200 bg-gray-50 focus:ring-gray-300 focus:border-gray-300  placeholder-gray-500 ") +
    " py-1 px-2 block text-sm w-full appearance-none rounded focus:outline-none focus:ring-1 placeholder-gray-500",
  ...rest
}) => {
  const [valueLength, setValueLength] = useState(0);
  const updateLengthValue = (event) => {
    if (maxLength && maxLength > 0) {
      setValueLength(event.target.value.length);
    }
  };

  return (
    <>
      {label && !maxLength && <Label>{label}</Label>}
      {label && maxLength && maxLength > 0 && (
        <div className="flex flex-row items-center justify-between">
          <Label>{label}</Label>

          <span
            className={
              "text-xs " +
              (maxLength - valueLength === 0
                ? "text-rose-500"
                : "text-gray-400")
            }
          >
            {maxLength - valueLength}
          </span>
        </div>
      )}
      <div className="relative">
        <Field
          as="textarea"
          className={className}
          rows={rows}
          maxLength={maxLength}
          placeholder={placeholder}
          onKeyUp={updateLengthValue}
          {...rest}
        />
      </div>
    </>
  );
};

export default Textarea;
