const Input = ({
  hasError = false,
  inline = true,
  className = (hasError
    ? "border-red-200 bg-red-50 "
    : "border-gray-200 bg-gray-50 ") +
    " border py-2 px-2 flex text-sm w-full appearance-none rounded  " +
    (inline ? "flex-row" : "flex-col"),
  children = null,
  ...rest
}) => {
  return (
    <div className="relative">
      <div className={className} {...rest}>
        {children}
      </div>
    </div>
  );
};

export default Input;
