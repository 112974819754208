import { Field } from "formik";

const Input = ({
  type = "text",
  placeholder = "",
  hasError = false,
  className = (hasError
    ? "border-red-200 bg-red-50 focus:ring-red-300 focus:border-red-300  placeholder-red-500 "
    : "border-gray-200 bg-gray-50 focus:ring-gray-300 focus:border-gray-300  placeholder-gray-500 ") +
    " py-1 px-2 block text-sm w-full appearance-none rounded focus:outline-none focus:ring-1 placeholder-gray-500",
  ...rest
}) => {
  return (
    <div className="relative">
      <Field
        type={type}
        className={className}
        placeholder={placeholder}
        {...rest}
      />
    </div>
  );
};

export default Input;
